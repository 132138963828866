import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useAppContext } from '../AppContextProvider'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppAppBar from './components/AppAppBar';
import MainContent from './components/MainContent';
import Latest from './components/Latest';
import Footer from './components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import PrivacyPolicy from './components/PrivacyPolicy';
import Home from './components/Home';

const content = (page) => {
  if(page == "privacy")
    return (<PrivacyPolicy />)
  else if(page == "home"){
    return (<Home/>)
  }
  else
  return(
  <Box>
    <MainContent />
    <Latest />
  </Box>);
}

export default function Blog(props) {
  const {page, setPage} = useAppContext();

  React.useEffect(() => {
    setPage(props?.content)
  }, [])
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4 }}
      >
        {content(page)}
      </Container>
      
      {page == "privacy" ? <></> :<Footer />}


    </AppTheme>
  );
}

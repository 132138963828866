import * as React from 'react';
import Button from '@mui/material/Button';
import Blog from './blog/Blog';
import logo from './logo.svg';
import './App.css';
import {AppContextProvider} from './AppContextProvider'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<Blog content="home" />} />
          <Route path='/privacy' element={<Blog content="privacy" />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;

import * as React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import SitemarkIcon from './SitemarkIcon';
import { ReactComponent as GooglePlayBadgeRu } from '../../gp_ru.svg';
import { ReactComponent as GooglePlayBadgeEn } from '../../gp_en.svg';
import { ReactComponent as RustoreBadge } from '../../rustore.svg';

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="https://canfiti.com/">
        canfiti
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  return (
    <React.Fragment>

      <Divider />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 8 },
          py: { xs: 8, sm: 10 },
          textAlign: { sm: 'center', md: 'left' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: 'center',
          }}
        >

          <Box sx={{
            display: 'flex',
            width: { xs: '100%', sm: '200px' },
            flexDirection: 'column',
            gap: 3
          }}>
            <a href="https://play.google.com/store/apps/details?id=com.canfiti">
              {t("LANG") == "ru_RU" ? <GooglePlayBadgeRu /> : <GooglePlayBadgeEn />}
            </a>
          </Box>
          <Box sx={{
            display: 'flex',
            width: { xs: '100%', sm: '20px' },
            flexDirection: 'column',
            gap: 3
          }}>
          </Box>

          <Box sx={{
            display: 'flex',
            width: { xs: '100%', sm: '200px' },
            flexDirection: 'column',
            gap: 3
          }}>
            {t("LANG") == "ru_RU" ? 
            <a href="https://www.rustore.ru/catalog/app/com.canfiti">
              <RustoreBadge />
            </a>
            : <></>}
          </Box>


        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 4, sm: 8 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
          <div>
            <Link color="text.secondary" variant="body2" href="/privacy">
              {t("PRIVACY")}
            </Link>
            <Copyright />
          </div>
          {/*
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ justifyContent: 'left', color: 'text.secondary' }}
          >
            <IconButton
              color="inherit"
              size="small"
              href="https://github.com/mui"
              aria-label="GitHub"
              sx={{ alignSelf: 'center' }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://x.com/MaterialUI"
              aria-label="X"
              sx={{ alignSelf: 'center' }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://www.linkedin.com/company/mui/"
              aria-label="LinkedIn"
              sx={{ alignSelf: 'center' }}
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>*/}
        </Box>
      </Container>
    </React.Fragment>
  );
}
